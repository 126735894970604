import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
  Observable
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { STORAGE_KEY } from 'src/utils/constants';
import { onError } from '@apollo/client/link/error';
import jwt from 'jwt-decode';
import { URL_CONFIG } from 'src/configs/url';

import { refresh, revokeRefresh } from 'src/operation/auth/mutation';

const link = createHttpLink({
  uri: URL_CONFIG.APOLLO_URL
});

const authLink = setContext((_, { headers, isPublic }) => {
  const token = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
  const authHeader = !isPublic &&
    token && {
      authorization: `Bearer ${token}`
    };

  return {
    headers: {
      ...headers,
      ...authHeader
    }
  };
});

function isTokenExpired(decodedToken): any {
  return new Date(decodedToken.exp * 1000) <= new Date();
}

const getNewToken = async (): Promise<any> => {
  const refreshToken = localStorage.getItem(STORAGE_KEY.REFRESH_TOKEN);
  if (!refreshToken) {
    return null;
  }
  const decodedToken: any = jwt(refreshToken);
  const id = decodedToken.id;

  const apolloClient = new ApolloClient({ link, cache: new InMemoryCache() });
  let isNewRefreshToken = false;
  if (isTokenExpired(decodedToken)) {
    apolloClient.mutate({
      mutation: revokeRefresh,
      variables: {
        input: {
          id,
          refreshToken: refreshToken
        }
      }
    })
    return null;
  }
  
  const { data, errors } = await apolloClient.mutate({
    mutation: refresh,
    variables: { input: { id, refreshToken, isNewRefreshToken } }
  });
  if (isNewRefreshToken) {
    localStorage.setItem(
      STORAGE_KEY.REFRESH_TOKEN,
      data.refresh_token.refresh_token
    );
  }
  if (data.refresh_token.access_token === null) {
    apolloClient.mutate({
      mutation: revokeRefresh,
      variables: {
        input: {
          id,
          refreshToken: refreshToken
        }
      }
    })
    return null;
  }

  return data.refresh_token.access_token;
};

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          // Apollo Server sets code to UNAUTHENTICATED
          // when an AuthenticationError is thrown in a resolver
          case STORAGE_KEY.UNAUTHENTICATED:
            return new Observable((observer) => {
              (async () => {
                try {
                  const newToken = await getNewToken();
                  if (!newToken) {
                    //Remove tokens
                    localStorage.clear();
                    location.reload();
                    return;
                  }
                  localStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, newToken);

                  // Modify the operation context with a new token
                  const oldHeaders = operation.getContext().headers;

                  operation.setContext({
                    headers: {
                      ...oldHeaders,
                      authorization: `Bearer ${newToken}`
                    }
                  });

                  const subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer)
                  };

                  // Retry last failed request
                  forward(operation).subscribe(subscriber);
                } catch (error) {
                  observer.error(error);
                }
              })();
            });
        }
      }
    }

    // To retry on network errors, we recommend the RetryLink
    // instead of the onError link. This just logs the error.
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  }
);

export const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, link]),
  cache: new InMemoryCache()
});
