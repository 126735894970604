import gql from 'graphql-tag';

export const login = gql`
  mutation Login($input: LoginInput = { password: "", username: "" }) {
    login(input: $input) {
      refresh_token
      access_token
      user {
        id
      }
      issuccess
      error
    }
  }
`;

export const logout = gql`
  mutation Logout {
    logout
  }
`;

export const refresh = gql`
  mutation RefreshToken(
    $input: RefreshTokenInput = { id: "", refreshToken: "", isNewRefreshToken: false }
  ) {
    refresh_token(input: $input) {
      access_token
      refresh_token
    }
  }
`;


export const revokeRefresh = gql`
  mutation RevokeRefresh(
    $input: RevokeRefreshInput = { id: "", refreshToken: "" }
  ) {
    revoke_refresh(input: $input)
  }
`
