import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';

import { Provider } from 'react-redux';
import store from '../src/store';
import { ApolloProvider } from '@apollo/client';
import { client } from 'src/utils/apollo-client';
import { SnackbarProvider } from 'notistack';
import { STORAGE_KEY } from './utils/constants';
import { AuthProvider } from './contexts/AuthContext';

ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <SidebarProvider>
        <BrowserRouter>
          <ApolloProvider client={client}>
            <SnackbarProvider
              autoHideDuration={STORAGE_KEY.SNACKBAR_AUTOHIDE_DURATION}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              maxSnack={STORAGE_KEY.SNACKBAR_MAX_STACK}
              preventDuplicate={true}
            >
              <AuthProvider>
                <App />
              </AuthProvider>
            </SnackbarProvider>
          </ApolloProvider>
        </BrowserRouter>
      </SidebarProvider>
    </HelmetProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
