export const STORAGE_KEY = {
    ACCESS_TOKEN: 'access_token',
    REFRESH_TOKEN: 'refresh_token',
    RESET_PASSWORD_TOKEN: 'resetPasswordToken',
    USERNAME: 'userName',
    PASSWORD: 'password',
    CURRENT_USER: 'currentUser',
    ID: 'id',
    UNAUTHENTICATED: 'invalid-jwt',
    PAGE_LIMIT: 10,
    COMBOBOX_LIMIT: 10,
    SNACKBAR_AUTOHIDE_DURATION: 3000, //MILISECOND
    SNACKBAR_MAX_STACK: 5,
    DATE_FORMAT: 'DD/MM/YYYY HH:mm:ss',
    RANGE_ALERT_DATE: 15,
    MAX_FILE_SIZE: 40, //MB
    API_SECRET: 'BBE2F37ECA6D9664584E2C58F78FE',
    OPTION_DATA_TYPE: {
      'text': 'text',
      'number': 'number',
      'datetime': 'datetime',
      'boolean': 'boolean'
    },
  };
  