import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/slider-layout';
import BaseLayout from 'src/layouts/base-layout';

// import Auth from 'src/layouts/auth';
// import UnAuth from 'src/layouts/un-auth';

import SuspenseLoader from 'src/components/SuspenseLoader';

import { TableProvider } from './contexts/TableContext';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const AddTableProvider = (component) => (
  <TableProvider>{component}</TableProvider>
);
const UnAuth = Loader(lazy(() => import('src/layouts/un-auth')));
const Auth = Loader(lazy(() => import('src/layouts/auth')));

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));

const Login = Loader(lazy(() => import('src/pages/Auth/Login')));

//Masterdata

const Server = Loader(lazy(() => import('src/pages/Masterdata/Server')));

const Company = Loader(lazy(() => import('src/pages/Masterdata/Company')));

const Station = Loader(lazy(() => import('src/pages/Masterdata/Station')));

const Software = Loader(lazy(() => import('src/pages/Masterdata/Software')));

const SoftwareGroup = Loader(
  lazy(() => import('src/pages/Masterdata/SoftwareGroup'))
);

//Management

const SoftwareVersion = Loader(
  lazy(() => import('src/pages/Management/SoftwareVersion'))
);

const OptionGroup = Loader(
  lazy(() => import('src/pages/Management/OptionGroup'))
);

// Dashboards

const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));

// Applications

const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);
const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

// Dashboard

const Dashboard = Loader(lazy(() => import('src/pages/Dashboard')));

//Mailbox

const ComposeEmail = Loader(lazy(() => import('src/pages/Mailbox/Compose')));

const EmailManagement = Loader(
  lazy(() => import('src/pages/Mailbox/Management'))
);

//Setting

const GeneralSetting = Loader(lazy(() => import('src/pages/Setting/General')));

//Report

const MonthlyRevenue = Loader(
  lazy(() => import('src/pages/Report/MonthlyRevenue'))
);

const RevenueByCompany = Loader(
  lazy(() => import('src/pages/Report/RevenueByCompany'))
);

const RevenueByStation = Loader(
  lazy(() => import('src/pages/Report/RevenueByStation'))
);

const DebtReport = Loader(lazy(() => import('src/pages/Report/DebtReport')));

const PaymentBillReport = Loader(
  lazy(() => import('src/pages/Report/PaymentBillReport'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <Auth />,
    children: [
      {
        path: '',
        element: <Dashboard />
      },
      {
        path: 'dashboard',
        element: <Dashboard />
      },
      ,
      {
        path: 'server',
        element: <Server />
      },
      {
        path: 'company',
        element: <Company />
      },
      {
        path: 'station',
        element: <Station />
      },
      {
        path: 'software',
        element: <Software />
      },
      {
        path: 'software-version',
        element: <SoftwareVersion />
      },
      {
        path: 'option-group',
        element: <OptionGroup />
      },
      {
        path: 'software-group',
        element: <SoftwareGroup />
      },
      {
        path: 'compose-email',
        element: <ComposeEmail />
      },
      {
        path: 'email-management',
        element: <EmailManagement />
      },
      {
        path: 'general-setting',
        element: <GeneralSetting />
      },
      {
        path: 'monthly-revenue',
        element: <MonthlyRevenue />
      },
      {
        path: 'revenue-by-company',
        element: <RevenueByCompany />
      },
      {
        path: 'revenue-by-station',
        element: <RevenueByStation />
      },
      {
        path: 'debt-report',
        element: <DebtReport />
      },
      {
        path: 'paymentbill-report',
        element: <PaymentBillReport />
      }
    ]
  },
  {
    path: '',
    element: <UnAuth />,
    children: [
      {
        path: '/login',
        element: <Login />
      }
    ]
  },
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'demo/dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="crypto" replace />
      },
      {
        path: 'crypto',
        element: <Crypto />
      },
      {
        path: 'messenger',
        element: <Messenger />
      }
    ]
  },
  {
    path: 'demo/management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="transactions" replace />
      },
      {
        path: 'transactions',
        element: <Transactions />
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: <UserProfile />
          },
          {
            path: 'settings',
            element: <UserSettings />
          }
        ]
      }
    ]
  },
  {
    path: 'demo/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  }
];

export default routes;
