import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

//reducers
import counterReducer from '../features/counter/counterSlice'

const store = configureStore({
 reducer: {
    counter: counterReducer,
 }
});

export default store;