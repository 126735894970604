import { FC, useState, createContext, useContext } from 'react';
import { ActiveStatus } from 'src/types';
import { STORAGE_KEY } from 'src/utils/constants';

type TableContext = {
  page: number;
  limit: number;
  active: ActiveStatus;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  setActive: React.Dispatch<React.SetStateAction<ActiveStatus>>;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TableContext = createContext<TableContext>(
  {} as TableContext
);

export const TableProvider: FC = ({ children }) => {
  const [limit, setLimit] = useState(STORAGE_KEY.PAGE_LIMIT);
  const [page, setPage] = useState(1);
  const [active, setActive] = useState<ActiveStatus>('active');
  const [searchText, setSearchText] = useState('');

  return (
    <TableContext.Provider
      value={{ limit, setLimit, page, setPage, active, setActive, searchText, setSearchText }}
    >
      {children}
    </TableContext.Provider>
  );
};

export const useTable = () => {
  const context = useContext(TableContext);

  if(!context) throw new Error("It's must be in TableProvider");

  return context;
}
